import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Row, Col } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Ecomheader from '../header/headershop';
import MobileMenu from '../navbarHead/mobileNav';
import Ecomfooter from '../footer/footershop'; 
import "../body/cart.css";
import "../header/headshop.css";
import "../navbarHead/navmenu.css";

const MilkRegister = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [addresses, setAddresses] = useState([]);
    const [addressOptions, setAddressOptions] = useState([]);

    useEffect(() => {
        if (!token) {
            navigate('/AdminLogin');
            return;
        }
        fetchClients();
        fetchAddressShipping();
    }, [token, navigate]);

    const fetchClients = async () => {
        try {
            const response = await fetch('http://68.178.169.226:12080/api/v1/login/getclientdetails', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 401 || response.status === 404) {
                navigate('/AdminLogin');
                return;
            }
            const data = await response.json();
            form.setFieldsValue({
                clientid: data.id,
                name: data.firstname,
                email: data.email,
                mobile: data.mobile,
            });
        } catch (error) {
            console.error('Error fetching client details:', error);
        }
    };

    const fetchAddressShipping = async () => {
        try {
            const payload = {
                clientid: localStorage.getItem("clientid"),
            };
            const response = await fetch("http://68.178.169.226:12080/api/v1/client/getaddress", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                setAddresses(data);
                setAddressOptions(data.map(address => ({
                    value: address.clientaddressid,
                    label: `${address.address1}, ${address.address2}, ${address.city} - ${address.pincode}`
                })));
            } else {
                console.error("Failed to get address billing");
            }
        } catch (error) {
            console.error("Error fetching address billing:", error);
        }
    };

    const handleAddressChange = (value) => {
        const selectedAddress = addresses.find(address => address.clientaddressid === value);
        if (selectedAddress) {
            form.setFieldsValue({
                address: `${selectedAddress.address1}, ${selectedAddress.address2}, ${selectedAddress.city} - ${selectedAddress.pincode}`,
            });
        }
    };

    const handleRegister = async (values) => {
        const clonedFormData = _.cloneDeep(values);

        try {
            const response = await axios.post('http://localhost:8080/api/customermilk/updatecustomer', {
                clientid: clonedFormData.clientid,
                firstname: clonedFormData.name,
                email: clonedFormData.email,
                mobileno: clonedFormData.mobile,
                address: clonedFormData.address,
                milkQuantity: clonedFormData.quantity,
                deliverDay: clonedFormData.day,
                milkitem: clonedFormData.selectlitre,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Added Milk requirements successfully:', response.data);
                navigate('/UserProfile');
            } else {
                console.error('Employee registration failed');
            }
        } catch (error) {
            console.error('Error registering employee:', error);
        }
    };

    return (
        <div>
            <Helmet>
                <title>Milk Register</title>
            </Helmet>
            <Ecomheader />
            <MobileMenu />
            <main className="main account container homebg">
                <nav className="breadcrumb-nav">
                    <div className="container">
                        <ul className="breadcrumb">
                        </ul>
                    </div>
                </nav>
                <div className="container pt-5">
                    <div className="row d-flex justify-content-center align-self-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-box-for-border">
                            <div className="form-box-header">
                                <h2>Milk Register</h2>
                            </div>
                            <div className="accountdetails">
                                <div className="tab-pane" id="account">
                                    <Form
                                        form={form}
                                        name='register'
                                        className='form'
                                        initialValues={{ remember: true }}
                                        onFinish={handleRegister}
                                        layout='vertical'
                                    >
                                        <Form.Item name='clientid' style={{ display: 'none' }}>
                                            <Input  />
                                        </Form.Item>
                                        <Form.Item
                                            label='Name'
                                            name='name'
                                            rules={[{ required: true, message: 'Please input your name!' }]}
                                        >
                                            <Input placeholder='Name' readOnly />
                                        </Form.Item>
                                        <Form.Item
                                            label='Email'
                                            name='email'
                                            rules={[{ required: true, message: 'Please input your email!' }]}
                                        >
                                            <Input placeholder='Email' readOnly />
                                        </Form.Item>
                                        <Form.Item
                                            label='Mobile'
                                            name='mobile'
                                            rules={[{ required: true, message: 'Please input your mobile number!' }]}
                                        >
                                            <Input placeholder='Mobile' readOnly />
                                        </Form.Item>
                                        <Form.Item
                                            label='Select Address'
                                            name='address'
                                            rules={[{ required: true, message: 'Please select an address!' }]}
                                        >
                                            <Select
                                                placeholder='Select Address'
                                                onChange={handleAddressChange}
                                            >
                                                {addressOptions.map(option => (
                                                    <Select.Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label='Select Litre'
                                            name='selectlitre'
                                            rules={[{ required: true, message: 'Please select a litre!' }]}
                                        >
                                            <Select placeholder='Select Litre'>
                                                <Select.Option value='250ML'>250ML</Select.Option>
                                                <Select.Option value='500ML'>500ML</Select.Option>
                                                <Select.Option value='1LITRE'>1LITRE</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label='Quantity'
                                            name='quantity'
                                            rules={[{ required: true, message: 'Please input the quantity!' }]}
                                        >
                                            <Input placeholder='Quantity' type='number' min={1} />
                                        </Form.Item>
                                        <Form.Item
                                            label='Day'
                                            name='day'
                                            rules={[{ required: true, message: 'Please select a day!' }]}
                                        >
                                            <Select placeholder='Select Day'>
                                                <Select.Option value='WEEKDAYS'>WEEKDAYS</Select.Option>
                                                <Select.Option value='WEEKEND'>WEEKEND</Select.Option>
                                                <Select.Option value='FULL MONTH'>FULL MONTH</Select.Option>
                                                <Select.Option value='OTHERS'>OTHERS</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type='primary' htmlType='submit' className='reg-submit'>
                                                REGISTER
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Ecomfooter />
        </div>
    );
}

export default MilkRegister;
