import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Ecomheader from './header/headershop';
import Ecomfooter from './footer/footershop';
import Category from './category';
import Product from './product';
import Cart from './body/cart'
import MenuSub from './menuSubcategory/index';
import Checkout from "../src/body/checkout";
import Billing from "../src/billing/index";
import HomePage from './home';
import AccountOrders from "../src/account/orders";
import AddressAccount from "../src/account/address";
import AccountDetails from "../src/account/accountdetails";
import MenuCategory from "./menuCategory/index";
import MenuSubSubCategory from "./menuSubsubCategory/index";
import Order from "../src/body/order";
import ShippingForm from '../src/shipping/index';
import Megashopourbest from '../src/bestSellers/index';
import FeaturedProduct from '../src/featurdproduct/index';
import PopupShow from "../src/popup/index";
import Terms from "../src/aboutus/terms";
import PrivacyPolicy from "../src/aboutus/privacypolicy";
import Termsconditionforseller from "../src/aboutus/TermsConditionSeller";
import AboutUs from "../src/aboutus/aboutus";
import CustomerService from "../src/aboutus/customerservice";
import ViewOrder from '../src/account/viewOrder';
import Reviews from '../src/reviews/index';
import PageNotFound from '../src/banner/pagenotfound';
import AllCategory from '../src/allCategories/index';
import WishList from '../src/wishlist/index';
import CustomProductsPage from './BestOffers_Featured_Search_Product_page/BestOffers_Featured_Search_Product_page';
import ProductWrap from '../src/product/productWrap';
import Megashopourcato from "../src/homecategory/index";
import PaymentSuccess from "../src/stripe/paymentsucess";
import MobileMenu from "../src/navbarHead/mobileNav";
import NavHead from '../src/navbarHead/index';
import SearchItems from '../src/searchPage/index';
import TopMenu from '../src/topmenu/index';
import ContactUs from './aboutus/contactus';
import SellerProduts from '../src/sellerProducts/index';
import ProductGallery from '../src/product/productGallery';
import ProductCategory from './component/productCategory';
import BuyCheckout from "../src/body/buyCheckOut";
import RegisterPopUp from "../src/popup/registerPopup";
import UserProfile from "../src/dashboard/index";
import MobileChange from "../src/account/profileMobileChange";
import ProfileChange from "../src/account/profileNameChange";
import AccountCoupons from "../src/account/coupons";
import NotificationList from "../src/account/notificationList";
import Reset from "../src/popup/resetPassword";
import ProductGalleryHover from "../src/product/productGalleryHover";
import MyVoucher from '../src/account/myVoucher';
import FirstTimeVoucher from "../src/theme/FirstTimeVoucher/index";
import MyComponent from "../src/component/metComponent";
import EditShipping from "../src/shipping/index-1";
import CartLogin from "../src/popup/cartLogin";
import NewOrders from "../src/account/newOrders";
import CancledOrders from "../src/account/cancelOrders";
import Followers from "../src/account/followersPage";
import MilkRegister from './milk/MilkRegister';
import MilkOrderStatus from './milk/MilkOrderStatus';
function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/HomePage" element={<HomePage />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/Ecomheader" element={<Ecomheader />} />
        <Route path="/Ecomfooter" element={<Ecomfooter />} />
        <Route path="/Product/:productid" element={<Product />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Checkout" element={<Checkout />} />
        <Route path="/Order" element={<Order />} />
        <Route path="/Billing" element={<Billing />} />
        <Route path="/MenuSub/:subcategoryid" element={<MenuSub />} />
        <Route path="/AccountOrders" element={<AccountOrders />} />
        <Route path="/AddressAccount" element={<AddressAccount />} />
        <Route path="/AccountDetails" element={<AccountDetails />} />
        <Route path="/menuCategory/:categoryid" element={<MenuCategory />} />
        <Route path="/menuSubsubCategory/:subsubcategoryid" element={<MenuSubSubCategory />} />
        <Route path="/ShippingForm" element={<ShippingForm />} />
        <Route path="/Megashopourbest/:productid" element={<Megashopourbest />} />
        <Route path="/FeaturedProduct/:productid" element={<FeaturedProduct />} />
        {/* <Route path="/LoginPage" element={<LoginPage />} /> */}
        <Route path="/LoginPage" element={<PopupShow />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Termsconditionforseller" element={<Termsconditionforseller />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/CustomerService" element={<CustomerService />} />
        <Route path="/ContactUS" element={<ContactUs />} />
        <Route path="/ViewOrder/:orderid" element={<ViewOrder />} />
        <Route path="/Reviews" element={<Reviews />} />
        <Route path="/PageNotFound" element={<PageNotFound />} />
        <Route path="/AllCategory" element={<AllCategory />} />
        <Route path="/WishList" element={<WishList />} />
        <Route path="/BestSellers" element={<CustomProductsPage pageName="BestSellers"  />} />
        <Route path="/FeaturedProduct" element={<CustomProductsPage pageName="FeaturedProduct"  />} />
        <Route path="/HourlyOffers" element={<CustomProductsPage pageName="HourlyOffers"  />} />
        <Route path="/ProductWrap" element={<CustomProductsPage pageName="ProductWrap"  />} />
        <Route path="/Megashopourcato" element={<CustomProductsPage pageName="Megashopourcato"  />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccess />}
/>
        <Route path="/NavHead" element={<NavHead />}/>
        <Route path="/MobileMenu" element={<MobileMenu />}/>
        <Route path="/SearchItems" element={<SearchItems />}/>
        <Route path="/TopMenu" element={<TopMenu />}/>
        <Route path="/SellerProduts/:sellerid" element={<SellerProduts />} /> {/* Pass sellerid parameter */}
        <Route path="/ProductGallery" element={<ProductGallery />}/>
        <Route path="/ProductCategory" element={<ProductCategory />}/>
        <Route path="/BuyCheckout" element={<BuyCheckout />}/>
        <Route path="/RegisterPopUp" element={<RegisterPopUp />}/>
        <Route path="/UserProfile" element={<UserProfile />}/>
        <Route path="/MobileChange" element={<MobileChange />}/>
        <Route path="/ProfileChange" element={<ProfileChange />}/>
        <Route path="/AccountCoupons" element={<AccountCoupons />}/>
        <Route path="/NotificationList" element={<NotificationList />}/>
        <Route path="/Reset" element={<Reset />}/>
        <Route path="/ProductGalleryHover" element={<ProductGalleryHover />}/>
        <Route path="/MyVoucher" element={<MyVoucher />}/>
        <Route path="/FirstTimeVoucher" element={<FirstTimeVoucher />}/>
        <Route path="/MyComponent" element={<MyComponent />}/>
        <Route path="/EditShipping/:clientaddressid" element={<EditShipping />}/>
        <Route path="/CartLogin" element={<CartLogin />}/>
        <Route path="/NewOrders" element={<NewOrders />}/>
        <Route path="/CancledOrders" element={<CancledOrders />}/>
        <Route path="/Followers" element={<Followers />}/>
        <Route path="/milk-register" element={<MilkRegister />}/>
        <Route path="/milk-order" element={<MilkOrderStatus />}/>

      </Routes>
    </Router>
  );
}

export default App;
