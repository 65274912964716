import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { deleteCart, editCart, addProduct, deleteProduct } from "../Redux/Redux";
import { Modal, Button } from 'antd';
import Megashopourbest from "../bestSellers";
const Cart = () => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [TotalproductData, setTotalProductData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productDatawithQuantity, setProductDatawithQuantity] = useState([]);
  const [productDatawithoutQuantity, setProductDatawithoutQuantity] = useState([]);
  const [productDataforQuantity, setproductDataforQuantity] = useState([]);
  const [totalSubtotal, setTotalSubtotal] = useState(0);
  const [carts, setCart] = useState(JSON.parse(localStorage.getItem('demo')) || []);
  const price = 122.99;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisibleforQuantity, setIsModalVisibleforQuantity] = useState(false);
  const [modalMessageforQuantity, setModalMessageforQuantity] = useState('');
  const [sellerProductsMap, setSellerProductsMap] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedSellers, setSelectedSellers] = useState([]);
  const [sellerCheckboxes, setSellerCheckboxes] = useState({});
  const wishlist = useSelector(state => state.wishlist.value)


  const cart = useSelector((state) => state.cart.value);
  const dispatch = useDispatch()
  const currencyType = localStorage.getItem('currencyName') != null ? localStorage.getItem('currencyName') : "";

  const cartData = cart;

  // Create an array of just the product IDs
  const productIds = cartData.map(item => item.productid);

  // Convert the product IDs to a comma-separated string
  const productIdsString = productIds.join(',');

  // Create a FormData object and append the productIds
  const formData = new FormData();
  formData.append("productIds", productIdsString);
  formData.append('currency', currencyType);
  const fetchProductData = async () => {
    try {
      // setIsLoading(true)
      setproductDataforQuantity([]);
      setProductDatawithQuantity([]);
      setProductDatawithoutQuantity([]);
      // Retrieve productIds from localStorage

      const response = await fetch(
        `http://68.178.169.226:12080/api/v1/product/getByIdarray`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const productData = await response.json();
        if (!productData.description) {
          setTotalProductData(productData);
          const quantityZeroProducts = productData.filter(product => product.quantity == 0);
          const quantityGreaterThanZeroProducts = productData.filter(product => product.quantity > 0);
          setProductDatawithQuantity(quantityGreaterThanZeroProducts);
          const productDataWithzeroQuantity = quantityZeroProducts.map(product => ({
            ...product,
            cartid: cartData.find(item => item.productid === product.productid)?.cartid
          }));
          setProductDatawithoutQuantity(productDataWithzeroQuantity);
          setproductDataforQuantity(quantityGreaterThanZeroProducts)
          // Make sure that productData is an array of products
          if (Array.isArray(quantityGreaterThanZeroProducts)) {
            // Set the initial quantity for each product based on cart data
            const productDataWithQuantity = quantityGreaterThanZeroProducts.map(product => ({
              ...product,
              quantity: cartData.find(item => item.productid === product.productid)?.productquantity || 1,
              cartid: cartData.find(item => item.productid === product.productid)?.cartid
            }));

            setProductData(productDataWithQuantity);
          }
          return await response.json();
        } else {
          setTotalProductData([]);
          setProductDatawithQuantity([]);
          setProductDatawithoutQuantity([]);
          setproductDataforQuantity([]);
          setProductData([]);
        }
      } else {
        console.error("Failed to fetch product data");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // setIsLoading(true)
    fetchProductData();
  }, [cart]);


  useEffect(() => {


    // Function to fetch client details to check authentication
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'http://68.178.169.226:12080/api/v1/login/getclientdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (response.ok) {
          setIsAuthenticated(true);
          // fetchCartDetails();
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);


  const handleIncrement = () => {
    // Increment the quantity by 1
    setQuantity(quantity + 1);
  };

  // Function to handle decrementing the quantity
  const handleDecrement = () => {
    // Ensure the quantity does not go below 1
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };
  React.useEffect(() => {
    const newSubtotal = price * quantity;
    setSubtotal(newSubtotal);
  }, [price, quantity]);

  const handleCheckout = async () => {
    try {
      await fetchProductData();
      const response = await fetch(`http://68.178.169.226:12080/api/v1/product/checkforquantity`, {
        method: "PUT",
        body: formData,
      });
      const qtyCheck = await response.json();

      if (qtyCheck.organiserId === "true") {
        navigate("/Checkout", { state: { productData } });
        const token = localStorage.getItem("token");
        if (token && token !== "null") {

          // Token is present and not null, proceed with checkout
          localStorage.setItem("productData", JSON.stringify(productData));
        } else {

          setIsPopupVisible(!isPopupVisible);

        }

        const selectedProductsData = productData.filter((product) =>
          selectedProducts.includes(product.productid)
        );
        navigate("/Checkout", { state: { productData: selectedProductsData } });
        // Save productData to localStorage
        localStorage.setItem("productData", JSON.stringify(selectedProductsData));
      }
    } catch (error) {
      console.error("Error fetching or navigating:", error);
    }
  };

  // Function to update the quantity for a specific product
  const updateProductQuantity = async (productId, cartid, newQuantity) => {
    fetchProductData();
    const isProductMatched = productDataforQuantity.find(product => product.productid === productId);
    if (isProductMatched && isProductMatched.quantity >= newQuantity) {
      dispatch(editCart({ productId, cartid, newQuantity }));
    } else {
      setModalMessageforQuantity(`Exceed minimun Value`);
      setIsModalVisibleforQuantity(true);
    }
  };




  useEffect(() => {
    // Calculate the total subtotal when productData or quantities change
    const totalSub = productData.reduce((total, product) => {
      return total + product.sellingprice * product.quantity;
    }, 0);

    setTotalSubtotal(totalSub);
  }, [productData]);
  // Function to update the cart icon value
  const updateCartIconValue = (newQuantity) => {
    setQuantity(newQuantity);
  };


  //function to remove item from cart 
  const handleRemoveProduct = (cartid) => {
    Modal.confirm({
      title: "Remove Product",
      content: "Are you sure you want to remove this product from your cart?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(deleteCart(cartid));
      },
      onCancel: () => {
        console.log("Cancelled product removal");
      },
    });
  };


  const removeItFromLocalStorage = (productId, cartid) => {
    // Find the index of the product to be removed
    const productIndex = productData.findIndex(
      (product) => product.productid === productId
    );

    // If the product is found, remove it from the productData array
    if (productIndex !== -1) {
      const updatedProductData = [...productData];
      updatedProductData.splice(productIndex, 1);
      setProductData(updatedProductData);

      // Remove the product from local storage as well
      const cartData = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedCartData = cartData.filter(
        (product) => product.productid !== productId
      );
      localStorage.setItem("cart", JSON.stringify(updatedCartData));
      // Update the cart icon value
      updateCartIconValue(updatedCartData.length);
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const addToWishList = (productid) => {
    if (!localStorage.getItem("token")) {
      setShowAlert(true);
      // Show alert and return early if clientid is not present
      return;
    }
    const isAlreadyInWishlist = wishlist.some(item => item.productid === productid);

    if (isAlreadyInWishlist) {
      // If the product is already in the wishlist, remove it
      const wishlistid = wishlist.find(item => item.productid === productid).wishlistid;
      removeProductID(wishlistid);
    } else {
      // If the product is not in the wishlist, add it
      dispatch(addProduct(productid));
    }

  };
  function removeProductID(wishlistid) {
    // let list = JSON.parse(localStorage.getItem("wishlist")) || []
    // list = list.filter(list => list.productid !== productid)
    // localStorage.setItem('wishlist', JSON.stringify(list))
    dispatch(deleteProduct(wishlistid))
    // window.location.reload(false)
  }
  const handleCheckboxChange = (productId) => {
    setSelectedProducts(prevSelectedProducts => {
      const updatedSelectedProducts = prevSelectedProducts.includes(productId)
        ? prevSelectedProducts.filter(id => id !== productId) // Remove product if already selected
        : [...prevSelectedProducts, productId]; // Add product if not already selected

      // After updating selectedProducts state, check seller selection
      const productData111 = productData.find((pro) => pro.productid === productId);
      const sellerProducts = sellerProductsMap[productData111.sellerid];
      const allSelected = sellerProducts.every(product => updatedSelectedProducts.includes(product.productid));

      console.log('selectedProducts:', updatedSelectedProducts);
      console.log('selectedSellers:', selectedSellers);

      // Update selectedSellers based on allSelected
      if (productData111) {
        if (allSelected && !selectedSellers.includes(productData111.sellerid)) {
          setSelectedSellers(prevSelectedSellers => [...prevSelectedSellers, productData111.sellerid]);
        } else if (!allSelected) {
          setSelectedSellers(prevSelectedSellers => prevSelectedSellers.filter(selectedSeller => selectedSeller !== productData111.sellerid));
        }
      }

      return updatedSelectedProducts;
    });
  };


  const handleSellerCheckboxChange = (sellerId) => {
    sellerId = parseInt(sellerId);
    console.log(selectedSellers);
    if (selectedSellers.includes(sellerId)) {
      // If sellerId is already selected, remove all products of that seller
      setSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(product => !sellerProductsMap[sellerId].find(sellerProduct => sellerProduct.productid === product)));
      setSelectedSellers(prevSelectedSellers => prevSelectedSellers.filter(selectedSeller => selectedSeller !== sellerId));
    } else {
      // If sellerId is not selected, add all products of that seller
      setSelectedProducts(prevSelectedProducts => {
        const updatedSelectedProducts = [...prevSelectedProducts];
        const sellerProducts = sellerProductsMap[sellerId];

        sellerProducts.forEach(product => {
          if (!updatedSelectedProducts.includes(product.productid)) {
            updatedSelectedProducts.push(product.productid);
          }
        });

        return updatedSelectedProducts;
      });
      setSelectedSellers(prevSelectedSellers => [...prevSelectedSellers, sellerId]);
      console.log(selectedSellers);
    };
  };

  useEffect(() => {
    // Update sellerCheckboxes state whenever selectedSellers changes
    const updatedCheckboxes = {};
    selectedSellers.forEach(sellerId => {
      updatedCheckboxes[sellerId] = true;
    });
    setSellerCheckboxes(updatedCheckboxes);
  }, [selectedSellers]);

  useEffect(() => {
    if (productData.length > 0) {
      // Group products by sellerid
      const groupedProducts = productData.reduce((acc, product) => {
        if (!acc[product.sellerid]) {
          acc[product.sellerid] = [];
        }
        acc[product.sellerid].push(product);
        return acc;
      }, {});

      setSellerProductsMap(groupedProducts);
      console.log("frtby", groupedProducts)
    }
  }, [productData]);

  useEffect(() => {
    // Calculate the total subtotal and total price when selectedProducts change
    const selectedProductsData = productData.filter(product => selectedProducts.includes(product.productid));
    const subtotal = selectedProductsData.reduce((total, product) => total + (product.sellingprice * product.quantity), 0);
    setTotalSubtotal(subtotal);

    // Calculate total price including taxes or shipping fees if applicable
    const totalPrice = subtotal; // Add taxes or shipping fees if applicable
    setTotalSubtotal(totalPrice);
  }, [selectedProducts, productData]);

  return (

    <div>

      <div>
        <Helmet>
          <title>Cart</title>
        </Helmet>
      </div>
      <Ecomheader />
      <main className="main cart container homebg" style={{ marginTop: '20px' }}>
        {isLoading ? (
          <div className="container-fluid">
            <div className="row">
              <div className="muti-col-loader"></div>
            </div>
          </div>
        ) : (
          <div className="page-content">
            {isAuthenticated && productData.length > 0 && (
              <div className="step-by pr-4 pl-4">
                <h3 className="title title-simple title-step active">
                  <Link to="/Cart">1. Cart</Link>
                </h3>
                <h3 className="title title-simple title-step">
                  {window.location.pathname === "/Cart" ? (
                    <span>2. CheckOut</span>
                  ) : (
                    isAuthenticated && productData.length > 0 ? (
                      <Link to="/Checkout">2. CheckOut</Link>
                    ) : (
                      <span>2. CheckOut</span>
                    )
                  )}

                </h3>
                <h3 className="title title-simple title-step">
                  {/* {isAuthenticated ? (
    <Link to="/Order">3. Order Complete</Link>
  ) : (
    <span>3. Order Complete</span>
  )} */}
                  <span>3. Complete</span>
                </h3>
              </div>
            )}
            {!isAuthenticated ? (

              <div className="container-fluid">
                {/* <img src="https://storage.megashop.my/nocart.jpg" alt="test" className="rounded mx-auto d-block" /> */}
                <div class="card">
                  <div class="alert alert-light alert-primary alert-icon mb-4 card-header">
                    <i class="fas fa-exclamation-circle"></i>
                    <span class="text-body">Returning customer?</span>
                    <Link to="/CartLogin" class="  " style={{ color: "#000" }} >Click here to login </Link>
                    OR else
                    <Link to="/RegisterPopup" class=" " style={{ color: "#000" }} >create a new account</Link>
                  </div>
                </div>
              </div>
            ) : (
              productData.length === 0 ? (
                <div className="">
                  {/* <img src="https://storage.megashop.my/nocart.jpg"  className="rounded mx-auto d-block" /> */}
                  <Link to="/HomePage">
                    <b className="text-center"><p>Your cart is empty, Please continue shopping.</p></b>
                  </Link>
                </div>


              ) : (
                <div className="container-fluied mb-2">



                  <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12 pr-lg-4">
                      {Object.entries(sellerProductsMap).map(([sellerId, products]) => (
                        <div key={sellerId}>
                          <div className="row cart-row cart-seller">
                            {/* <div className="form-checkbox col-md-1 col-sm-1 d-flex justify-content-center">
                    <div className="checkbox-set mt-1">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        id={`checkbox-seller-${sellerId}`}
                        checked={sellerCheckboxes[sellerId]}
                        onChange={() => handleSellerCheckboxChange(sellerId)}
                      />
                      <label
                        className="form-control-label ls-s"
                        htmlFor={`checkbox-seller-${sellerId}`} 
                      ></label>
                    </div>
                  </div>
      <h6 >{products[0].sellername === "Admin" ? 'R-Ecom' : products[0].sellername}</h6> */}
                          </div>
                          <div className="container-fluied">
                            {products.map((product) => (
                              <div className="row cart-row" key={product.productid}>

                                <div className="form-checkbox   col-md-1 col-sm-1 d-flex justify-content-center">
                                  <div className="checkbox-set mt-1">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox"
                                      id={`checkbox-${product.productid}`}
                                      onChange={() => handleCheckboxChange(product.productid)} // Pass only the productId here
                                      checked={selectedProducts.includes(product.productid)}
                                    />
                                    <label
                                      className="form-control-label ls-s"
                                      htmlFor={`checkbox-${product.productid}`}
                                    >
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-8  col-sm-11 col-md-11">
                                  <div className="row">
                                    <div className="col-lg-4  col-sm-3 col-md-2 product-img">
                                      <Link
                                        to={`/Product/${product.productid}`}
                                        key={product.productid}
                                        onClick={() => localStorage.setItem("productid", product.productid)} className=""
                                      >
                                        <figure>
                                          <img
                                            src={(product.previewimg && product.previewimg.file) ? product.previewimg.file : "https://storage.megashop.my/noproduct.jpg"}
                                            alt={product.productname}
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                              objectFit: "contain",
                                              overflow: "hidden"
                                            }}
                                          />
                                        </figure>
                                      </Link>
                                      <div className="product-test-wishlist relative"> <>
                                        <div className="absolute z-10 right-4 top-3">
                                          <div
                                            className={`btn-product-icon btn-wishlist ${wishlist.some(
                                              (wishlistItem) =>
                                                wishlistItem.productid === product.productid
                                            )
                                              ? "wishlist-has-items"
                                              : ""
                                              }`}
                                            title="Add to wishlist"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              addToWishList(product.productid);
                                            }}
                                          >
                                            <i className="d-icon-heart-full"></i>
                                          </div>
                                        </div>
                                        {/* <div  className="product-qty">
            <button
              className="cart-btn"
              onClick={(e) => {
                e.preventDefault()
                addToCart(product.productid)
              }}
            >
              Add to Cart
            </button></div> */}
                                      </></div>
                                    </div>
                                    <div className="col-lg-7  col-sm-9 col-md-5">
                                      <Link
                                        to={`/Product/${product.productid}`}
                                        key={product.productid}
                                        onClick={() => localStorage.setItem("productid", product.productid)}
                                      >
                                        <p className="product-name">{product.productname}</p>

                                      </Link>

                                      <span className="amount">
                                        {product.currency === 'SGD' ? 'S$' :
                                          product.currency === 'MYR' ? 'RM' :
                                            product.currency === 'INR' ? '₹' :
                                              product.currency} {product.sellingprice}
                                      </span>

                                      {/* Only show MRP price if it's different from selling price */}
                                      {product.sellingprice !== product.mrpprice && (
                                        <span className="amount text-red-500 opacity-70 mrp-price" style={{ color: "var(--rm-red)" }}>
                                          {product.currency === 'SGD' ? 'S$' :
                                            product.currency === 'MYR' ? 'RM' :
                                              product.currency === 'INR' ? '₹' :
                                                product.currency} {product.mrpprice}
                                        </span>
                                      )}
                                      {product.mrpprice - product.sellingprice > 0 && (
                                        <span className="amount save-price" style={{ color: "var(--rm-yellow)" }}>
                                          {product.currency === 'SGD' ? 'S$' :
                                            product.currency === 'MYR' ? 'RM' :
                                              product.currency === 'INR' ? '₹' :
                                                product.currency} {product.mrpprice - product.sellingprice.toFixed(2)} save
                                        </span>
                                      )}

                                      {/* <div className="seller-name">
                                        Seller Name : {product.sellername === "Admin" ? "R-Ecom" : product.sellername}
                                      </div> */}
                                      <div className="ratings-container col-md-12 col-sm-12 mt-md-2">
                                        <div className="ratings-full">
                                          <span className="ratings" style={{ width: `${(product.review && product.review.averate_rating) ? (parseFloat(product.review.averate_rating) * 20) + '%' : '0%'}` }}></span>

                                          <span className="tooltiptext tooltip-top"></span>
                                        </div>
                                        <a
                                          href="#"
                                          className="link-to-tab rating-reviews"
                                        >
                                          (Reviews {product.review && product.review.review_count})
                                        </a>
                                      </div>
                                      <div className="flex items-center cart-qty mt-2">
                                        {product.quantity > 1 ? <span
                                          className="cart-qty-btn"
                                          onClick={() => {
                                            updateProductQuantity(
                                              product.productid,
                                              product.cartid,
                                              parseInt(product.quantity) - 1
                                            )
                                          }}
                                        >-</span> :
                                          (<span
                                            className="cart-qty-btn"
                                          ></span>)}
                                        <span className="cart-qty-value">
                                          {product.quantity}
                                        </span>

                                        <span
                                          className="cart-qty-btn "
                                          onClick={() =>
                                            updateProductQuantity(
                                              product.productid,
                                              product.cartid,
                                              parseInt(product.quantity) + 1
                                            )
                                          }
                                        >+</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div className="col-lg-3 col-sm-12 col-md-7 mt-1  d-flex justify-content-end">

                                  <div><a
                                    href="#"
                                    className="product-remove"
                                    title="Remove this product"
                                    onClick={() => { handleRemoveProduct(product.cartid) }} // Call the function when "X" is clicked
                                  >
                                    Remove
                                  </a></div>
                                </div>




                                {/* <div className="col-lg-2  col-sm-3 col-md-2  text-right">
                          <span className="amount">
                                  {localStorage.getItem('currencySymbol')} {(product.sellingprice * product.quantity).toFixed(2)}
                           </span>

                          </div> */}

                              </div>
                            ))}
                          </div>

                        </div>
                      ))}
                    </div>
                    <aside className="col-lg-4 col-sm-12 justify-content-end  mt-5">
                      <div className="sticky-sidebar" data-sticky-options="{'bottom': 20}" >
                        <div className="summary mb-4">
                          <h3 className="summary-title text-left">Cart Totals</h3>
                          <table className="shipping">
                            <tr className="summary-subtotal">
                              <td>
                                <h4 className="summary-subtitle">Subtotal</h4>
                              </td>
                              <td>
                                <p className="summary-subtotal-price">
                                  {localStorage.getItem('currencySymbol')}{totalSubtotal.toFixed(2)}
                                </p>
                              </td>
                            </tr>
                          </table>
                          <table className="total">
                            <tr className="summary-subtotal">
                              <td>
                                <h4 className="summary-subtitle">Total</h4>
                              </td>
                              <td>
                                <p className="summary-total-price ls-s">
                                  {localStorage.getItem('currencySymbol')} {totalSubtotal.toFixed(2)}
                                </p>
                              </td>
                            </tr>
                          </table>
                          <div>

                          </div>


                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        {isAuthenticated && productData.length > 0 && (!productDatawithoutQuantity || productDatawithoutQuantity.length == 0) ? (selectedProducts.length > 0 ? (
                          <div
                            className="btn btn-dark btn-rounded btn-order"
                            onClick={handleCheckout}
                          >
                            Proceed to Checkout
                          </div>
                        ) : (
                          <p className="message text-danger">
                            Please select a product to proceed to checkout.
                          </p>
                        )
                        ) : null}
                      </div>
                    </aside>
                  </div>
                </div>
              )
            )}
          </div>

        )}
      </main>
      <Modal
        visible={isModalVisibleforQuantity}
        onOk={() => setIsModalVisibleforQuantity(false)}
        onCancel={() => setIsModalVisibleforQuantity(false)}
        footer={[
          <Button key="ok" type="primary" onClick={() => setIsModalVisibleforQuantity(false)}>
            OK
          </Button>,
        ]}
      >
        {modalMessageforQuantity}
      </Modal>
      <Megashopourbest />
      <Ecomfooter />
    </div>
  );
}
export default Cart;