import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import Ecomfooter from "../../src/footer/footershop";
import Ecomheader from "../../src/header/headershop";
import CommonHome from "../../src/facillity/commonHome";
import Megashopship from "../../src/facillity/index";
import MenuSub from "../../src/menuSubcategory/index";
import noimage from '../../src/images/noimage.jpg';
import { Helmet } from "react-helmet";
import Zoom from 'react-medium-image-zoom';
import "../product/product.css";
import { useDispatch, useSelector } from "react-redux";
import { Input, Form, Upload, Button, Modal, notification } from 'antd';
import { addCart, editCart } from "../Redux/Redux";
import { UploadOutlined } from '@ant-design/icons';

import MobileMenu from "../../src/navbarHead/mobileNav";
import ProductWrap from "../../src/product/productWrap";
import SellerProfileDetails from "../component";
import { addProduct, deleteProduct } from "../Redux/Redux";
import ProductGallery from "../../src/product//productGallery";
import ProductCategory from "../../src/component/productCategory";
import { LeftOutlined } from '@ant-design/icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
  PinterestIcon,
} from "react-share";




const Product = () => {
  const { productid } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [description, setDescription] = useState(true);
  const [data, setData] = useState([]);
  const [dataforproductGroupnumber, setProductGroupNumber] = useState([]);
  const [quantity1, setQuantity1] = useState(1);
  const [isReviewFormVisible, setReviewFormVisible] = useState(false);
  const [rating, setRating] = useState("5");
  const [review, setReview] = useState("");
  const [canWriteReview, setCanWriteReview] = useState(false);
  const [isClientIdPresent, setIsClientIdPresent] = useState(false);
  const [clientReviewData, setClientReviewData] = useState({});
  const [editedreview, seteditedreview] = useState("");
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [selectedImage, setSelectedImage] = useState(data.productimage || '');
  const [showPopup, setShowPopup] = useState(false);
  const [fileid, setUploadfiles] = useState([]);
  const [fileList, setFileList] = useState([]); // To store uploaded files
  const [currentPage, setCurrentPage] = useState(1);
  const wishlist = useSelector(state => state.wishlist.value)
  const [relatedProduct, setCategoriesforrelated] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [categoryResponse, setCategoryResponse] = useState({});
  const reviewsPerPage = 3;
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = review.slice(indexOfFirstReview, indexOfLastReview);
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [getvarients1, setGetVarients] = useState([]);
  const [isModalVisibleforQuantity, setIsModalVisibleforQuantity] = useState(false);
  const [modalMessageforQuantity, setModalMessageforQuantity] = useState('');
  const [removeModalforCartProductConfirm, setRemoveModalforCartProductConfirm] = useState(false);
  const [isModalVideoOpen, setIsModalVideoOpen] = useState(false);
  const priceDifference1 = (data.mrpprice - data.sellingprice);
  const truncatedNumber = Math.floor(priceDifference1 * 100) / 100;
  const [loading, setLoading] = useState(true); // Loading state
  const [addressBilling, setAddressBilling] = useState([]);
  const [isModalOpenforReport, setIsModalOpenforReport] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [isSubModalVisible, setIsSubModalVisible] = useState(false);
  const [reportText, setReportText] = useState('');
  const [icons, setIcons] = useState([]);
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    console.log("dataforproductGroupnumber:", dataforproductGroupnumber);
    console.log("productid:", productid);
    if (dataforproductGroupnumber) {
      if (!dataforproductGroupnumber.some(item => item.productid === productid)) {
        console.log("Reload needed");
        fetchData(productid)
      } else {
        console.log("No reload needed");
      }
    }
  }, [productid]);
  const openModalforReport = () => {
    setIsModalOpenforReport(true);
  };

  const closeModalforReport = () => {
    setIsModalOpenforReport(false);
  };
  const openSubModal = (reason) => {
    setSelectedReason(reason);
    setIsSubModalVisible(true);
  };

  const closeSubModal = () => {
    setIsSubModalVisible(false);
  };

  const handleReportTextChange = (e) => {
    setReportText(e.target.value);
  };

  const handleReportSubmit = () => {
    // Handle report submission here
    console.log(`Reporting for reason: ${selectedReason}`);
    console.log(`Report text: ${reportText}`);

    // Close sub modal after report submission
    closeSubModal();
    closeModalforReport();
  };
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart.value)

  // useEffect hook to update quantity1 when cart item changes
  useEffect(() => {
    console.log("jhvuyftdyrexcyfiytciuvfgv")
    const cartItem = cart.find((cartItem) => cartItem.productid === data.productid);
    if (cartItem) {
      setQuantity1(cartItem.productquantity);
    }
  }, [cart]); // Run this effect whenever the cart changes

  // Function to handle quantity change
  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value);
    setQuantity1(newQuantity);
    // Additional logic to update the cart item quantity in the Redux store
  };

  const handleThumbnailClick = (file) => {
    setSelectedImage(file.file);
  };
  const toggleReviewForm = (val) => {
    setReviewFormVisible(val);
  };
  // const [cart, setCart] = useState(
  //   JSON.parse(localStorage.getItem("cart")) || []
  // );
  const isProductInCart = cart.some(
    (item) => item.productid === data.productid
  );
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    setUserIsLoggedIn(!!token);
  }, []);
  // useEffect(() => {
  // Check if data.productid is different from productid
  // if (data.productid !== productid) {
  //   // If different, call fetchData
  // fetchData();
  // }
  // [data.productid, productid]); // useEffect dependencies

  useEffect(() => {
    fetchData();

    seconFetchData(productid)
  }, []);

  useEffect(() => {
  }, []);
  const changeSize = (productid) => {
    seconFetchData(productid)
  }
  const seconFetchData = async (productid1) => {
    try {

      // setLoading(true);
      const payload = {
        productid: parseInt(productid1),
        clientid: parseInt(localStorage.getItem("clientid")),
        currency: localStorage.getItem('currencyName')
      };
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/product/getById",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        return;
      }

      setData(response.data);
      // setLoading(false);
    } catch {

    }
  }
  const fetchData = async () => {
    try {
      setLoading(true);
      const payload = {
        productid: parseInt(productid),
        clientid: parseInt(localStorage.getItem("clientid")),
        currency: localStorage.getItem('currencyName')
      };
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/product/getById",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        return;
      }

      setData(response.data);
      const getVarients = await axios.put("http://68.178.169.226:12080/api/v1/product/getvarient", {
        productid: parseInt(productid),
      },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
      setGetVarients(getVarients.data);
      const productnumber = response.data.productgroupnumber;
      const groupdate = await axios.put(
        "http://68.178.169.226:12080/api/v1/product/getbyproductgroupnumber",
        {
          productgroupnumber: productnumber,
          currency: localStorage.getItem('currencyName')
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setProductGroupNumber(groupdate.data);
      setCanWriteReview(data.canwritereview);
      const CategoryIdforRelated = response.data.categoryid;

      const categoryResponse = await axios.put(
        "http://68.178.169.226:12080/api/v1/product/getbyCategory",
        {
          categoryid: CategoryIdforRelated,
          currency: localStorage.getItem('currencyName')
        }
      );

      if (categoryResponse.status === 401 || categoryResponse.status === 404) {
        return;
      }

      setCategoriesforrelated(categoryResponse);

      // Log product names to the console
      if (categoryResponse.data && Array.isArray(categoryResponse.data)) {
        const names = categoryResponse.data.map((product) => product.productname);
        setProductNames(names);
      }
      setCategoryResponse(categoryResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  };
  const { sellerid } = data;
  const navigateToSellerProducts = () => {
    // Navigate to the SellerProducts page with the sellerid as a parameter
    navigate(`/SellerProduts/${sellerid}`);
  };
  const [showAlert, setShowAlert] = useState(false);

  const handleCartClick = async () => {
    try {
      if (!localStorage.getItem("token")) {
        setShowAlert(true);
        return;
      }
      dispatch(addCart({ productid: data.productid, quantity1: quantity1 }));
      // const response = await fetch(
      //   "http://68.178.169.226:12080/api/v1/cart/add",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     },
      //     body: JSON.stringify({
      //       // clientid: cartid,
      //       clientid:localStorage.getItem("clientid"),
      //       productid: data.productid,
      //       productquantity: 1,
      //     }),
      //   }
      // );

      // if (response.ok) {
      //   handleCart();
      // }
      handleQuantityChange(quantity1);
      showNotificationforCart();
    } catch (error) {
      console.error("Error checking authentication:", error);
    }
  };
  const closeAlert = () => {
    setShowAlert(false);
  };
  const showNotificationforCart = () => {
    notification.success({
      message: 'Product added to the cart',
    });
  };
  function stripHTMLTags(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }
  const reviewSubmit = async (e) => {
    e.preventDefault();
    const clientid = localStorage.getItem("clientid");
    const clientname = sessionStorage.getItem("clientname");
    try {
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/review/add",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            reviewid: clientReviewData.reviewid,
            rating: rating,
            review: editedreview != null ? editedreview : review,
            clientid: clientid,
            clientname: clientname,
            productid: parseInt(productid),
            reviewimages: fileid,
          }),
        }
      );
      if (response.status === 401 || response.status === 404) {
        // navigate("/LoginPage");
        return; // Exit early to prevent further logic execution
      }
      if (response.ok) {
        setCanWriteReview(true);
        window.location.reload();
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const closePopup = () => {
    // Close the pop-up
    setShowPopup(false);
  };
  const generateStars = (selectedRating) => {
    const stars = [];
    for (let i = 1; i <= selectedRating; i++) {
      stars.push(
        <span key={i} className={`star-${i} active`}>
          &#9733;
        </span>
      );
    }
    return stars;
  };

  const getreviewsById = async (e) => {
    try {
      const response2 = await fetch(
        "http://68.178.169.226:12080/api/v1/review/getreviewsforclient",
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientid: parseInt(sessionStorage.getItem("clientid")),
            productid: parseInt(productid),
          }),
        }
      );
      if (response2.status === 401 || response2.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate("/LoginPage");
        return; // Exit early to prevent further logic execution
      }
      if (response2.ok) {
        const data = await response2.json();
        setClientReviewData(data);
        seteditedreview(data.review);
        if (data && data.length > 0) {
        } else {
        }
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  useEffect(() => {
    // Define the API endpoint
    const apiUrl = "http://68.178.169.226:12080/api/v1/review/getreviews";

    // Define the request payload
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productid: parseInt(productid),
      }),
    };

    // Make the API request
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setReview(data); // Update the state with the received data

        const sessionClientId = parseInt(sessionStorage.getItem("clientid"));
        const allClientIdsMatch = data.every(
          (review) => review.clientid === sessionClientId
        );
        if (allClientIdsMatch) {
          setIsClientIdPresent(allClientIdsMatch);
          getreviewsById();
          const matchingReviews = data.filter(
            (review) => review.clientid === sessionClientId
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  }, []);

  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("http://68.178.169.226:12080/api/v1/fileupload/file", formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress({ percent: percentCompleted });
        },
        headers: {
          Authorization: 'Bearer ' + token, // Set the Authorization header with the token
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        // Redirect to the login page for 401 or 404 errors or if the token is not present
        navigate('/LoginPage');
        return; // Exit early to prevent further logic execution
      }

      setUploadfiles((prevUploadFiles) => [...prevUploadFiles, response.data.organiserId]);
      onSuccess("File uploaded");
    } catch (error) {
      onError("File upload failed");
    }
  };


  const contentRef = useRef(null);

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const updateProductQuantity = async (productId, operation) => {
    let cartItem = cart.find((cartItem) => cartItem.productid == productId)
    if (operation == 'add') {
      handleQuantityChange(quantity1);
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity: parseInt(quantity1) + 1 }))
      showNotification('Added 1 quantity in cart');
    }
    else {
      handleQuantityChange(quantity1);
      if (parseInt(quantity1) === 1) {
        setRemoveModalforCartProductConfirm(true);
      } else {
        dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity: parseInt(quantity1) - 1 }))
        showNotification('Removed 1 quantity in cart');
      }
    }
  };
  const showNotification = (message) => {
    notification.success({
      message: message,
    });
  }
  const handleRemoveConfirmation = () => {
    let cartItem = cart.find((cartItem) => cartItem.productid === data.productid);
    dispatch(editCart({ productId: data.productid, cartid: cartItem.cartid, newQuantity: 0 }));
    setRemoveModalforCartProductConfirm(false);
  };
  const formatDate = (dateString) => {
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [imageClass, setImageClass] = useState(' ');
  useEffect(() => {
    const imageElement = new Image();
    imageElement.src = selectedImage || data.productimage;

    imageElement.onload = () => {
      const { width, height } = imageElement;

      // Determine if the image is horizontal or vertical
      if (width > height) {
        setImageClass('img-horizontal');
      }
      else if (width === height) {
        setImageClass('img-horizontal');
      }
      else {
        setImageClass('img-vertical');
      }
    };
  }, [selectedImage, data.productimage]);
  const handleProductClick = (productId) => {
    window.location.href = `/Product/${productId}`;
  };
  const handleImageClick = (product) => {
    if (parseInt(productid) !== product.productid) {
      navigate(`/Product/${product.productid}`);
      localStorage.setItem("productid", product.productid)
      window.location.reload();
    }
  };
  const handleNavigate = () => {
    if (!localStorage.getItem("token")) {

      setShowAlert(true);
    }
    if (userIsLoggedIn) {
      const navigationState = { quantity: quantity1, productDetails: data };
      navigate('/BuyCheckout', { state: navigationState });
    }

    else {
      <Modal
        visible={showAlert}
        onCancel={closeAlert}
        footer={[
          <Button key="login" type="btn btn-dark btn-block btn-rounded" onClick={() => {
            closeAlert();
            window.location.href = '/LoginPage';
          }}>
            Login
          </Button>,
        ]}
      >
        <p>Please Login to Proceed.</p>
      </Modal>
    }
  }
  const [colorvariants, setColourvariants] = useState([]);
  useEffect(() => {
    if (data.varients) {
      const parsedVariants = JSON.parse(data.varients).UniqueColors;
      setColourvariants(parsedVariants);
    }
  }, [data]);

  // const [sizevariants, setSizevariants] = useState([]);
  // const [availableSize, setAvailableSize] = useState([]);
  // useEffect(() => {
  //   if (data.varients) {
  //     const parsedVariants = JSON.parse(data.varients);
  //     setSizevariants(parsedVariants.UniqueSizes);
  //     setAvailableSize(parsedVariants.AvailableSize);
  //   }
  // }, [data]);

  // const isSizeAvailable = (size) => availableSize  && availableSize.includes(size);
  const addToWishList = (productid) => {
    if (!localStorage.getItem("token")) {
      setShowAlert(true);
      // Show alert and return early if clientid is not present
      return;
    }
    const isAlreadyInWishlist = wishlist.some(item => item.productid === productid);

    if (isAlreadyInWishlist) {
      // If the product is already in the wishlist, remove it
      const wishlistid = wishlist.find(item => item.productid === productid).wishlistid;
      removeProductID(wishlistid);
    } else {
      // If the product is not in the wishlist, add it
      dispatch(addProduct(productid));
    }

  };

  function removeProductID(wishlistid) {
    // let list = JSON.parse(localStorage.getItem("wishlist")) || []
    // list = list.filter(list => list.productid !== productid)
    // localStorage.setItem('wishlist', JSON.stringify(list))
    dispatch(deleteProduct(wishlistid))
    // window.location.reload(false)
  }
  const showModal = () => {
    setIsModalImageOpen(true);
  };

  const handleCancel = () => {
    setIsModalImageOpen(false);
    setZoomLevel(100);
  };
  const showModalforVideo = () => {
    setIsModalVideoOpen(true);
  }
  const handleCancelforVideo = () => {
    setIsModalVideoOpen(false);

  }
  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      if (zoomLevel < 200) {
        setZoomLevel(zoomLevel + 10);
      }
    } else {
      if (zoomLevel > 50) {
        setZoomLevel(zoomLevel - 10);
      }
    }
  };
  const updateProductQuantiyDirect = (value) => {
    if (value != 'sub') {
      if (quantity1 < parseInt(data.quantity)) {
        setQuantity1(quantity1 + 1)
      } else {
        setModalMessageforQuantity(`Exceed maximum Value`);
        setIsModalVisibleforQuantity(true);
        console.log('Cannot increase quantity beyond available quantity');
      }
    }
    else {
      if (quantity1 > 1) {

        setQuantity1(quantity1 - 1)
      } else {
        setModalMessageforQuantity(`Exceed minimun Value`);
        setIsModalVisibleforQuantity(true);
      }
    }
  }
  const handleAlraedyAdded = () => {
    notification.warning({
      message: 'Already added to the Cart',
      duration: 2,
    });
  }
  const fetchAddressBilling = async () => {
    try {
      // Define the payload with the client ID
      const payload = {
        clientid: localStorage.getItem("clientid"),
      };

      // Make the API request to get address billing with the payload
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/client/getaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Set the address billing data in state
        setAddressBilling(data);
      } else {
        console.error("Failed to get address billing");
      }
    } catch (error) {
      console.error("Error fetching address billing:", error);
    }
  };
  useEffect(() => {
    fetchAddressBilling();
  }, []);
  useEffect(() => {
    const socialIcons = async () => {
      try {
        const response = await fetch('http://68.178.169.226:12080/api/v1/socialmedia/get', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setIcons(data);
        } else {
        }
      } catch (error) {
        console.error('Error fetching icon:', error);
      }
    };
    socialIcons();
  }, []);
  const shareUrl = `http://68.178.169.226:12080/Product/${productid}`;
  const title = `${data.productname}`;
  const description1 = `${data.sortdescription}`;
  const imageUrl = data.previewimg && data.previewimg.file ? data.previewimg.file : 'https://example.com/default-image.jpg';

  const imageWidth = 1200;
  const imageHeight = 630;
  return (
    <div className="page-content">
      <div>
        <Helmet>
          <title>{data.productname}</title>
        </Helmet>
      </div>
      <Ecomheader />
      <MobileMenu />
      <div className=" ">

        <div className="container homebg" style={{ marginTop: '30px' }}>



          {loading ? (

            <div className="container-fluied">
              <div className="row">
                <div className="muti-col-loader"></div>
              </div>
            </div>

          ) : (
            <>
              <div className="product product-single row  main-content-wrap align-items-center">
                <ProductGallery data={data} noimage={noimage} previewImage={data.previewimg && data.previewimg.file}
                  fileModelImages={data.fileModel} youTube={data.youtube && data.youtube} />


                <div className="col-md-6 col-lg-6 col-sm-12">
                  <div className="product-details">
                    <div className="row breadcrumb">
                      <div className="col-md-6 col-sm-6 m-0 p-0">
                        {data.categoryname && data.subcategoryname ? (
                          <span className="">
                            {data.categoryname} {" > " + data.subcategoryname}
                            {data.subsubcategoryname && " > " + data.subsubcategoryname}
                          </span>
                        ) : (
                          <p className="">{data.categoryname}</p>
                        )}
                      </div>
                      <div className="col-md-6 col-sm-6 d-flex justify-content-end ">
                        <div className="btn-product-icon btn-wishlist" onClick={(e) => {
                          e.preventDefault();
                          addToWishList(data.productid);
                        }}>{wishlist.some(item => item.productid === data.productid)
                          ? "Remove Wishlist"
                          : "Add to Wishlist"
                          }</div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 product-name m-0 p-0">
                        <h1>{data.productname}</h1>

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 price-box m-0 p-0">
                        <div className="container-fluid row pb-1 pt-1">
                          <div className="col-md-8 col-sm-12 p-0 m-0">
                            <div className="product-price">
                              {data.mrpprice !== data.sellingprice ? (
                                <>
                                  <ins className="new-price">
                                    {localStorage.getItem('currencySymbol')}
                                    {data.sellingprice}
                                  </ins>
                                  <del className=" text-red-500 opacity-70 " style={{ color: "var(--rm-red)" }}>
                                    {localStorage.getItem('currencySymbol')}
                                    {data.mrpprice}
                                  </del>
                                </>
                              ) : (
                                <span className="single-price">
                                  {localStorage.getItem('currencySymbol')}
                                  {data.sellingprice}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-md-end  col-sm-12 justify-content-sm-start p-0  m-0">
                            {data.mrpprice !== data.sellingprice &&
                              <div className="save-price">

                                {localStorage.getItem('currencySymbol')}{" "}{truncatedNumber}  Save
                              </div>}
                          </div>
                        </div>
                      </div>


                    </div>
                    {/* <div className="row mt-2">
                      <div className="col-md-2 col-sm-2 m-0 p-0">Return</div>
                      <div className="col-md-10 col-sm-10"><strong>Free Returns</strong><Link to="/Terms"> Change of Mind</Link></div>                     
                    </div> */}
                    {/* <div className="row mt-2">
                      <div className="col-md-2 col-sm-3 m-0 p-0">Protection</div>
                      <div className="col-md-10 col-sm-9"><strong>Damage Protection</strong><Link to="/Terms"> Learn More</Link></div>

                    </div> */}

                    {userIsLoggedIn &&
                      <div className="row mt-2">
                        <div className="col-md-2 col-sm-3 m-0 p-0">Shipping</div>
                        <div className="col-md-10 col-sm-9 d-flex">
                          <div>
                            {addressBilling.length > 0 &&

                              <b>
                                {addressBilling[0].name}, {addressBilling[0].city}, {addressBilling[0].state}, {addressBilling[0].pincode}
                              </b>

                            }
                          </div></div>

                      </div>
                    }
                    <div className="row">


                    </div>
                    <div className="row sold-review">
                      <div className="col-md-12 sold-out-label col-sm-6 mt-md-2">
                        Sold Out {data.soldquantity}/{data.quantity}
                      </div>
                      <div className="ratings-container col-md-12 col-sm-6 mt-md-2">
                        <div className="ratings-full">
                          <span className="ratings" style={{ width: `${(data.review && data.review.averate_rating) ? (parseFloat(data.review.averate_rating) * 20) + '%' : '0%'}` }}></span>

                          <span className="tooltiptext tooltip-top"></span>
                        </div>
                        <a
                          href="#product-tab-reviews"
                          className="link-to-tab rating-reviews"
                        >
                          (Reviews {data.review && data.review.review_count})
                        </a>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      {data.color && (
                        <div className="row color-varient">
                          <div className="product-color">Colour :
                            <input type="text"
                              value={data.colorname}
                              style={{
                                marginLeft: "10px",
                              }} />
                          </div>

                        </div>
                      )}
                      <div className="col-md-12  col-sm-12 mt-sm-2 mt-2">
                        {getvarients1 && getvarients1.UniqueColors && (
                          <div className="variants">
                            {getvarients1.UniqueColors.map((colorVariant, index) => (
                              <Link
                                key={colorVariant.productid}
                                onClick={() => changeSize(colorVariant.productid)}
                              >
                                <div key={index} style={{ border: `5px solid ${colorVariant.color}`, display: "inline-block" }} className={colorVariant.color === data.color ? "varient-color  varient-color-selected" : "varient-color"}>

                                  <img
                                    src={colorVariant.previewimg}
                                    alt={`Color ${colorVariant.previewimg}`}

                                  />
                                  {/* <span style={{ color: colorVariant.color }}>{colorVariant.color}</span> */}
                                </div>
                              </Link>
                            ))}
                          </div>
                        )}</div>
                    </div>
                    <div className="row color-size">
                      <div className="col-md-12 col-sm-12 p-0">
                        {data.size && (
                          <div className="product-size">Size : <input type="text"
                            value={data.size}
                            style={{
                              marginLeft: "10px",
                            }} /></div>
                        )}</div>
                      <div className="col-md-12 col-sm-12 mt-sm-2 mt-2 p-0">

                        {getvarients1 && getvarients1.UniqueSizes && getvarients1.UniqueColors ? (
                          <div className="variants">
                            {getvarients1.UniqueSizes.map((sizeVariant, index) => {
                              const availableSize = getvarients1.AvailableSizesForColor[data.color]?.find(item => item.size === sizeVariant.size);
                              const isSelected = availableSize && availableSize.productid === data.productid;

                              return (
                                <div
                                  key={index}
                                  className={`varient-size ${isSelected ? 'varient-size-selected' : (availableSize ? '' : 'varient-size-disabled')}`}
                                  onClick={() => availableSize && changeSize(availableSize.productid)}
                                >
                                  <div>
                                    {sizeVariant.size}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="variants">
                            {getvarients1.UniqueSizes &&
                              getvarients1.UniqueSizes.map((sizeVariant, index) => {
                                const isSelected = sizeVariant.productid === data.productid;

                                return (
                                  <div
                                    key={index}
                                    className={`varient-size ${isSelected ? 'varient-size-selected' : ''}`}
                                    onClick={() => sizeVariant && changeSize(sizeVariant.productid)}
                                  >
                                    <div>
                                      {sizeVariant.size}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>)}
                      </div>
                    </div>





                    {/* {sizevariants &&
  <div className="variants">
    {Array.from(new Set(sizevariants.map(variant => variant.size)))
      .map(size => {
        const variantsForSize = sizevariants.filter(variant => variant.size === size);
        return variantsForSize.sort((a, b) => a.productid - b.productid);
      })
      .flat()
      .map((variant, index) => (
        <div key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
          {variant.productid !== data.productid ? (
            <Link to={`/Product/${variant.productid}`} onClick={() => changeSize(variant.productid)}>
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                }}
              >
                {variant.size}
              </div>
            </Link>
          ) : (
            <div
              style={{
                width: '20px',
                height: '20px',
                textAlign: 'center',
                backgroundColor: 'gray',
              }}
            >
              {variant.size}
            </div>
          )}
        </div>
      ))}
  </div>
} */}


                    <div className="row">
                      <div className="col-md-12 m-0 p-0">
                        {data.producttypedata && data.producttypedata.type === "silver" && (
                          <div className="product-size">
                            <div> Weight: {data.weight}
                              {data.productunit}</div>
                            <div> Material: {data.material}</div>
                            <div> Packaging content: {data.packagecontent}</div>
                            <div> Packaging weight: {data.packageweight}</div>
                            <div> Packaging unit: {data.packagingunit}</div>
                            <div> Breakable: {data.fragile && (
                              <div className="fragile-status">
                                This product is breakable.
                              </div>
                            )}</div>
                          </div>
                        )
                        }
                      </div>
                    </div>



                    <div className="row rate-qty mt-2">

                      <div className="col-md-6 col-sm-7 p-0">
                        {!isProductInCart &&
                          <div className="d-flex align-items-center" onClick={(e) => e.preventDefault()}>
                            <div className="align-self-center pr-4">
                              <label for="quantity-input" className="block mb-2 text-base font-semibold text-gray-900 dark:text-white">Quantity :</label>
                            </div>
                            <div className="product-qty">
                              <button type="button" className="product-qty-sub"
                                onClick={(e) => {
                                  e.preventDefault();
                                  updateProductQuantiyDirect('sub')
                                }}
                              >
                                <svg className="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                                </svg>
                              </button>
                              <span className="align-self-center" >
                                {quantity1}
                                {data.quantity < quantity1 && setQuantity1(parseInt(data.quantity))}
                              </span>
                              <button type="button" class="product-qty-add"
                                onClick={(e) => {
                                  e.preventDefault();
                                  updateProductQuantiyDirect('add')
                                }}
                              >
                                <svg className="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        }

                        {isProductInCart &&
                          <div className="d-flex align-items-center" onClick={(e) => e.preventDefault()}>
                            <div className="align-self-center pr-4">
                              <label for="quantity-input" className="block mb-2 text-base font-semibold text-gray-900 dark:text-white">Quantity :</label>
                            </div>
                            <div className="product-qty">
                              <button type="button" className="product-qty-sub"
                                onClick={(e) => {
                                  e.preventDefault();
                                  updateProductQuantity(data.productid, 'sub')
                                }}
                              >
                                <svg className="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                                </svg>
                              </button>
                              <span className="align-self-center" >
                                {quantity1}
                              </span>
                              <button type="button" className="product-qty-add"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const currentQuantity = quantity1;
                                  const availableQuantity = parseInt(data.quantity); // Assuming data.quantity is the available quantity from the API
                                  if (currentQuantity < availableQuantity) {
                                    updateProductQuantity(data.productid, 'add');
                                  } else {
                                    setModalMessageforQuantity(`Exceed maximum Value`);
                                    setIsModalVisibleforQuantity(true);
                                    console.log('Cannot increase quantity beyond available quantity');
                                  }
                                }}
                              >
                                <svg className="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="col-md-6 col-sm-5 d-flex align-items-center">
                        <div> {parseInt(data.quantity)} Qty
                        </div>
                      </div>
                      {/* <div className="col-md-3 col-sm-3 p-0 d-flex justify-content-end">
                        <a onClick={scrollToContent}>See more</a>
                      </div> */}

                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-4 col-sm-12 p-0" >
                        <div className="product-form-group">
                          {parseInt(data.quantity) > 0 ? (
                            <>
                              {!isProductInCart && <button
                                className="cart-btn buyer-cart-btn"
                                // style={{ display: isProductInCart ? "none" : "block" }}
                                onClick={() => {
                                  handleNavigate();
                                }}
                              >
                                <i className="d-icon-bag"></i> {'Buy'}
                              </button>}

                              {!isProductInCart && <button
                                className="cart-btn"
                                // style={{ display: isProductInCart ? "none" : "block" }}
                                onClick={handleCartClick}
                              >
                                <i className="d-icon-bag"></i> {'Add to Cart'}
                              </button>}

                            </>
                          ) : (
                            <button className="cart-btn">Sold Out</button>
                          )}

                        </div>
                        {isProductInCart &&
                          <div className="product-form-group">
                            <button
                              className="cart-btn  buyer-cart-btn"
                              onClick={() => {
                                handleNavigate();
                              }}
                            >
                              <i className="d-icon-bag"></i> {'Buy'}
                            </button>
                            <button
                              className="cart-btn"
                              onClick={() => {
                                handleAlraedyAdded();
                              }}
                            >
                              <i className="d-icon-bag"></i> {'Added to Cart'}
                            </button>
                          </div>
                        }
                      </div>
                    </div>


                    {/* <div className="row mt-2">
                      <p className="col-md-4 col-sm-4 m-0 p-0">R-Ecom Guarantee</p>
                      <p className="col-md-8 col-sm-8"><strong>Get the items you ordered or get your money back.</strong></p>
                    </div> */}
                    {/* <div className="row">
                      {userIsLoggedIn &&
                      <div className="col-md-6 col-sm-6 m-0 p-0" onClick={openModalforReport}>
                        Report
                      </div>
                      }
                      </div> */}

                  </div>
                  {/* <div className="share-buttons row">
                    <div className="col-md-1">
        <FacebookShareButton style={{ color: 'blue' }} url={shareUrl}>
          <FacebookIcon size={50} round />
        </FacebookShareButton>
     <Helmet>
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content={imageWidth.toString()} />
        <meta property="og:image:height" content={imageHeight.toString()} />
      </Helmet> 
      
        </div>
                    <div className="col-md-1">
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={50} round />
        </TwitterShareButton>
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description1} />
          <meta name="twitter:image" content={imageUrl} />
        </Helmet>
        </div>
        
        <div className="col-md-1">
      <WhatsappShareButton url={shareUrl} title={title} >
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>
        </div>
        
        <div className="col-md-1">
        <FacebookMessengerShareButton url={shareUrl}>
    <FacebookMessengerIcon size={50} round />
  </FacebookMessengerShareButton>
  </div>
  
  <div className="col-md-1">
  <PinterestShareButton url={shareUrl} media={data.previewimg && data.previewimg.file}>
    <PinterestIcon size={50} round />
  </PinterestShareButton>
  </div>
  
      </div>  */}
                  <div>
                  </div>

                </div>
                {/* <div className="col-lg-3 facility-product-page">
                  <ProductCategory />
                </div> */}
              </div>



              <SellerProfileDetails id={data.sellerid} />


              <div className="tab tab-nav-simple product-tabs">
                <ul className="nav nav-tabs justify-content-center" role="tablist">
                  <li className="nav-item">
                    <a
                      className={description ? "nav-link active" : "nav-link"}
                      onClick={() => setDescription(true)}
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={!description ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setDescription(false);
                      }}
                    >
                      (Reviews {data.review && data.review.review_count})
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  {description ? (
                    <div className="tab-pane active in" id="product-tab-description">
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <h5 className="description-title m-0 p-0">
                            Features
                          </h5>
                          <div className="mb-2 productDescription-style" ref={contentRef} dangerouslySetInnerHTML={{ __html: data.fulldetails }}></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="" id="product-tab-reviews">
                      <div className="row">
                        <div className="col-lg-4  col-sm-12 mb-6">
                          <div className="avg-rating-container">
                            <mark>{data.review && data.review.averate_rating ? parseFloat(data.review.averate_rating).toFixed(1) : ''}</mark>
                            <div className="avg-rating">
                              <span className="avg-rating-title">Average Rating</span>
                              <div className="ratings-container mb-0">
                                <div className="ratings-full">
                                  <span
                                    className="ratings"
                                    style={{ width: "100%" }}
                                  ></span>
                                  <span className="tooltiptext tooltip-top"></span>
                                </div>
                                <span className="rating-reviews">({data.review && data.review.review_count} Reviews)</span>
                              </div>
                            </div>
                          </div>
                          <div className="ratings-list mb-2">
                            <div className="ratings-item">
                              <div className="ratings-container mb-0">
                                <div className="ratings-full">
                                  <span
                                    className="ratings"
                                    style={{ width: "100%" }}
                                  ></span>
                                  <span className="tooltiptext tooltip-top"></span>
                                </div>
                              </div>
                              <div className="rating-percent">
                                <span style={{ width: "100%" }}></span>
                              </div>
                              <div className="progress-value">100%</div>
                            </div>
                            <div className="ratings-item">
                              <div className="ratings-container mb-0">
                                <div className="ratings-full">
                                  <span
                                    className="ratings"
                                    style={{ width: "80%" }}
                                  ></span>
                                  <span className="tooltiptext tooltip-top">4.00</span>
                                </div>
                              </div>
                              <div className="rating-percent">
                                <span style={{ width: "0%" }}></span>
                              </div>
                              <div className="progress-value">0%</div>
                            </div>
                            <div className="ratings-item">
                              <div className="ratings-container mb-0">
                                <div className="ratings-full">
                                  <span
                                    className="ratings"
                                    style={{ width: "60%" }}
                                  ></span>
                                  <span className="tooltiptext tooltip-top">4.00</span>
                                </div>
                              </div>
                              <div className="rating-percent">
                                <span style={{ width: "0%;" }}></span>
                              </div>
                              <div className="progress-value">0%</div>
                            </div>
                            <div className="ratings-item">
                              <div className="ratings-container mb-0">
                                <div className="ratings-full">
                                  <span
                                    className="ratings"
                                    style={{ width: "40%" }}
                                  ></span>
                                  <span className="tooltiptext tooltip-top">4.00</span>
                                </div>
                              </div>
                              <div className="rating-percent">
                                <span style={{ width: "0%;" }}></span>
                              </div>
                              <div className="progress-value">0%</div>
                            </div>
                            <div className="ratings-item">
                              <div className="ratings-container mb-0">
                                <div className="ratings-full">
                                  <span
                                    className="ratings"
                                    style={{ width: "20%" }}
                                  ></span>
                                  <span className="tooltiptext tooltip-top">4.00</span>
                                </div>
                              </div>
                              <div className="rating-percent">
                                <span style={{ width: "0%" }}></span>
                              </div>
                              <div className="progress-value">0%</div>
                            </div>
                          </div>
                          <div>
                            {canWriteReview ? (
                              <div>
                                <textarea
                                  value={editedreview}
                                  onChange={(e) => seteditedreview(e.target.value)}
                                ></textarea>
                                <button
                                  className="btn btn-dark btn-rounded"
                                  onClick={reviewSubmit}
                                >
                                  Edit Review
                                </button>
                              </div>
                            ) : (
                              <button
                                className="btn btn-dark btn-rounded submit-review-toggle"
                                href="!"
                                onClick={() =>
                                  setReviewFormVisible(!isReviewFormVisible)
                                }
                              >
                                Add Review
                              </button>
                            )}
                          </div>

                          {isReviewFormVisible && (
                            <div className="modal-background">

                              <form onSubmit={reviewSubmit}>
                                <div className="review-popup">
                                  <div className="review-overlay"></div>
                                  <div className="review-form-wrapper">
                                    <div className="title-wrapper text-left">
                                      <h3 className="title title-simple text-left text-normal">
                                        Add a Review
                                      </h3>
                                      <p>
                                        Your email address will not be published.
                                        Required fields are marked *
                                      </p>
                                    </div>
                                    <div className="rating-form">
                                      <label for="rating" className="text-dark">
                                        Your rating *{" "}
                                      </label>
                                      <select
                                        value={rating}
                                        onChange={(e) => setRating(e.target.value)}
                                        required
                                      >
                                        <option value="5">Perfect</option>
                                        <option value="4">Good</option>
                                        <option value="3">Average</option>
                                        <option value="2">Not that bad</option>
                                        <option value="1">Very poor</option>
                                      </select>
                                      <div className="selected-stars">
                                        {generateStars(parseInt(rating))}
                                      </div>
                                    </div>
                                    <textarea
                                      id="reply-message"
                                      cols="30"
                                      rows="6"
                                      className="form-control mb-4"
                                      placeholder="Comment *"
                                      required
                                      value={editedreview}
                                      onChange={(e) => seteditedreview(e.target.value)}
                                    ></textarea>
                                    <div className="review-medias">
                                      <label className="form-label" name="fileid">Upload Images</label>
                                      <Form.Item
                                        name="fileid"
                                      >
                                        <Upload
                                          customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
                                          fileList={fileList}
                                          onChange={({ fileList }) => setFileList(fileList)}
                                        >
                                          <Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>Click to Upload</Button>
                                        </Upload>
                                      </Form.Item>
                                    </div>
                                    <p>
                                      Upload images and videos. Maximum count: 3,
                                      size: 2MB
                                    </p>
                                    <button
                                      type="submit"
                                      className="btn btn-dark btn-rounded"
                                    >
                                      Submit<i className="d-icon-arrow-right"></i>
                                    </button>
                                    <button
                                      onClick={() => setReviewFormVisible(false)}
                                      className="btn btn-dark btn-rounded"
                                      style={{ marginLeft: "10%" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </form>

                            </div>
                          )}
                        </div>
                        <div className="col-lg-8 comments pt-2 pb-10 border-no">
                          {/* <nav className="toolbox">
                            <div className="toolbox-left">
                              <div className="toolbox-item">
                                <a href="!" className="btn btn-outline btn-rounded">
                                  All Reviews
                                </a>
                              </div>
                              <div className="toolbox-item">
                                <a href="!" className="btn btn-outline btn-rounded">
                                  With Images
                                </a>
                              </div>
                              <div className="toolbox-item">
                                <a href="!" className="btn btn-outline btn-rounded">
                                  With Videos
                                </a>
                              </div>
                            </div>
                            <div className="toolbox-right">
                              <div className="toolbox-item toolbox-sort select-box text-dark">
                                <label>Sort By :</label>
                                <select name="orderby" className="form-control">
                                  <option value>Default Order</option>
                                  <option value="newest" selected="selected">
                                    Newest Reviews
                                  </option>
                                  <option value="oldest">Oldest Reviews</option>
                                  <option value="high_rate">Highest Rating</option>
                                  <option value="low_rate">Lowest Rating</option>
                                  <option value="most_likely">Most Likely</option>
                                  <option value="most_unlikely">Most Unlikely</option>
                                </select>
                              </div>
                            </div>
                          </nav> */}
                          <div className="comment-list">
                            {currentReviews.map((review, index) => (
                              <div className="comment-body" key={index}>
                                <div className="comment-rating ratings-container">
                                  <div className="ratings-full">
                                    <span
                                      className="ratings"
                                      style={{ width: review.rating * 20 + "%" }}
                                    ></span>
                                    <span className="tooltiptext tooltip-top"></span>
                                  </div>
                                </div>
                                <div className="comment-user">
                                  <span className="comment-date">
                                    by {" "}
                                    <span className="font-weight-semi-bold text-uppercase text-dark">
                                      {localStorage.getItem('clientid') == review.clientid ? 'you' : review.clientname}
                                    </span>{" "}
                                    <span className="font-weight-semi-bold text-dark">
                                      {formatDate(review.createDate)}
                                    </span>
                                  </span>
                                </div>

                                <div className="comment-content mb-5">
                                  <p>{review.review || "No comment available."}</p>
                                </div>
                                {review.files && review.files.length > 0 && (
                                  <div className="review-images">
                                    {review.files.map((image, imgIndex) => (
                                      <img
                                        key={imgIndex}
                                        src={image.file}  // Assuming 'image.file' is the URL of the image
                                        alt={`Review Image ${imgIndex + 1}`}
                                        className="review-image"
                                        style={{ width: '100px', height: '100px' }}
                                      />
                                    ))}
                                  </div>
                                )}


                                {/* <div className="feeling mt-5">
                            <button className="btn btn-link btn-icon-left btn-slide-up btn-infinite like mr-2">
                              <i className="fa fa-thumbs-up"></i>
                              Like (<span className="count">0</span>)
                            </button>
                            <button className="btn btn-link btn-icon-left btn-slide-down btn-infinite unlike">
                              <i className="fa fa-thumbs-down"></i>
                              Unlike (<span className="count">0</span>)
                            </button>
                          </div> */}
                              </div>
                            ))}
                          </div>

                          {/* <nav className="toolbox toolbox-pagination justify-content-end">
                            <ul className="pagination">
                              {Array.from({ length: Math.ceil(review.length / reviewsPerPage) }, (_, index) => (
                                <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index + 1}>
                                  <a
                                    className="page-link"
                                    href="!"
                                    onClick={() => handlePageChange(index + 1)}
                                  >
                                    {index + 1}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </nav> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>


              <div className="row">
                <div className=" col-12">
                  <h2 className="section-title">Related Products</h2>
                  <Link to={`/MenuCategory/${data.categoryid}`} className="section-link">View All</Link>
                </div>
              </div>


              <div className="row">

                {categoryResponse.data && Array.isArray(categoryResponse.data) ? (
                  categoryResponse.data.slice(0, 50).map((product, index) => (
                    //       <div key={product} className="row">
                    // {/* <Link

                    <ProductWrap key={product.productid} product={product} />

                    // </div>
                  ))
                ) : (
                  <p>No products found.</p>
                )}

              </div>

            </>
          )}
        </div>
        <Modal
          visible={showAlert}
          onCancel={closeAlert}
          footer={[
            <Button key="login" type="btn btn-dark btn-block btn-rounded d-flex" onClick={() => {
              closeAlert();
              window.location.href = '/LoginPage';
            }}>
              Login
            </Button>,
          ]}
        >
          <p>Please Login to Proceed.</p>
        </Modal>
        <Modal
          visible={isModalImageOpen}
          onCancel={handleCancel}
          onWheel={handleScroll}
          footer={null}
          className="zoom-modal-content"
        >
          <div className="zoom-container" onWheel={handleScroll}>
            <img
              src={data.previewimg && data.previewimg.file}
              alt={data.productid}
              style={{ transform: `scale(${zoomLevel / 100})` }}
            />
          </div>
        </Modal>
        <Modal
          visible={isModalVideoOpen}
          onCancel={handleCancelforVideo}
          footer={null}
        >
          <div >
            <iframe
              src={data.youtube != null ? data.youtube : 'Test'}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen="true"
            ></iframe>
          </div>
        </Modal>
        {showPopup && (
          <div id="success-popup">
            <p>You have successfully added a review.</p>
            <button onClick={closePopup}>Cancel</button>
          </div>
        )}
        <Modal
          visible={isModalVisibleforQuantity}
          onOk={() => setIsModalVisibleforQuantity(false)}
          onCancel={() => setIsModalVisibleforQuantity(false)}
          footer={[
            <Button key="ok" type="primary" onClick={() => setIsModalVisibleforQuantity(false)}>
              OK
            </Button>,
          ]}
        >
          {modalMessageforQuantity}
        </Modal>
        <Modal
          title="Remove Item"
          visible={removeModalforCartProductConfirm}
          onOk={handleRemoveConfirmation}
          onCancel={() => setRemoveModalforCartProductConfirm(false)}
        >
          <p>Are you sure you want to remove this product from the cart?</p>
        </Modal>
        <Modal
          visible={isModalOpenforReport}
          title="Select a Reason"
          onCancel={closeModalforReport}
          footer={null}
          width={600}
        >
          <ul style={{ fontSize: '20px' }}>
            <li onClick={() => openSubModal('Counterfeits and copyright')}>Counterfeits and copyright</li>
            <li onClick={() => openSubModal('Prohibited (banned) items')}>Prohibited (banned) items</li>
            <li onClick={() => openSubModal('Listing policy violations (improper keywords, outside links, etc)')}>Listing policy violations (improper keywords, outside links, etc)</li>
            <li onClick={() => openSubModal('Offensive and potentially offensive items')}>Offensive and potentially offensive items</li>
            <li onClick={() => openSubModal('Fraudulent listings (illegal seller demands, etc.)')}>Fraudulent listings (illegal seller demands, etc.)</li>
            <li onClick={() => openSubModal('Unauthorized copied listing')}>Unauthorized copied listing</li>
          </ul>
        </Modal>
        <Modal
          title={<div><span onClick={() => { closeSubModal(); closeModalforReport(); }} style={{ cursor: 'pointer' }}>&larr;</span> {selectedReason}</div>}
          visible={isSubModalVisible}
          onCancel={() => { closeSubModal(); closeModalforReport(); }}
          footer={[
            <Button key="submit" type="primary" onClick={handleReportSubmit}>
              Send Report
            </Button>
          ]}
        >
          <textarea rows="4" value={reportText} onChange={handleReportTextChange} />
        </Modal>
        <Ecomfooter />
      </div>
    </div>
  );
};
export default Product;
